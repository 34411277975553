import '../../sass/sideBar.css';
import tiktokLogo from '../../img/socialMedia/tiktokLogo.svg';
import instagramLogo from '../../img/socialMedia/instagramLogo.svg';
import iphoneAd from '../../img/iPhoneAd.png';

const SideBar = () => {

    return (
        <aside className="sideBarContainer">
            <div className="sideBar-FollowBox">
                <p className="sideBar-FollowBox-bigText">Zaobserwuj nas</p>
                <p className="sideBar-FollowBox-text">Zaobserwuj nas na Instagramie i TikToku, aby być na bieżąco!</p>
                <div className="sideBar-FollowBox-iconWrapper">
                    <a href="https://www.instagram.com/verita.news/" target="_blank" rel="noopener noreferrer">
                        <img className="sideBar-FollowBox-iconWrapper__icon" src={instagramLogo} alt="instagram logo"/>
                    </a>
                    <a href="https://www.tiktok.com/@verita.news" target="_blank" rel="noopener noreferrer">
                        <img className="sideBar-FollowBox-iconWrapper__icon" src={tiktokLogo} alt="tiktok logo"/>
                    </a>
                </div>
            </div>
            <div className="sideBar-AppBox">
                <p className="sideBar-FollowBox-bigText">Zainstaluj naszą aplikację mobilną</p>
                <div className="sideBar-FollowBox-imageWrapper">
                    <img className="sideBar-FollowBox-imageWrapper__image" src={iphoneAd} alt="iPhone Ad"/>
                </div>
                <p className="sideBar-FollowBox-bigText-green">Bądź na bieżąco!</p>
            </div>
        </aside>
);
};

export default SideBar;
