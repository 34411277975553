import React, {useState, useEffect, useContext} from 'react';
import {useParams} from 'react-router-dom';
import {CapacitorHttp} from '@capacitor/core';
import {useNavigate} from "react-router-dom";
import '../sass/articleDetails.css';
import Timer from "../components/technicalComponents/Timer";
import newsPaperIcon from "../img/icons/newsPaperIcon.svg";
import arrowIcon from "../img/icons/greenArrow.svg";
import Loader from "../components/technicalComponents/Loader";
import YoutubeVideo from "../utils/YoutubeVideo";
import LineChartWrapper from "../utils/LineChartWrapper.";
import NotFoundError from "../components/technicalComponents/NotFoundError";
import newsPaperIconDark from "../img/icons/newsPaperIconDark.svg";
import {ThemeContext} from "../components/context/ThemeContext";
import { Tweet } from 'react-twitter-widgets';

import placeHolder from '../img/ErrorFetchingImage.png';
import placeHolderDark from '../img/ErrorFetchingImageDark.png';


function ArticleDetails() {
    const {id} = useParams();
    const [article, setArticle] = useState(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 800);
    const [error, setError] = useState(false);
    const navigate = useNavigate();
    const {theme} = useContext(ThemeContext);
    const placeHolderImage = theme === 'light' ? placeHolder : placeHolderDark;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 800);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleViewAllSources = () => {
        navigate(`/post/${article.itemId}/sources`, {state: {article}});
    };

    function getArticleText(count) {
        if (count === 1) {
            return 'artykuł';
        } else if ((count > 1 && count < 5) || (count % 10 > 1 && count % 10 < 5 && (count % 100 < 10 || count % 100 >= 20))) {
            return 'artykuły';
        } else {
            return 'artykułów';
        }
    }

    useEffect(() => {
        const fetchArticle = async () => {
            const options = {
                url: `https://cms.verita.news/v1/post/news/${id}`,
            };

            try {
                const response = await CapacitorHttp.get(options);
                if (response.status === 200) {
                    setArticle(response.data);
                } else {
                    setError(true);
                }
            } catch (error) {
                setError(true);
                console.error('Error fetching article:', error);
            }
        };

        fetchArticle();

        const sendViewedRequest = async () => {
            const viewedUrl = `https://cms.verita.news/v1/post/news/${id}/viewed`;
            const viewedOptions = {
                url: viewedUrl,
            };

            try {
                await CapacitorHttp.get(viewedOptions);
            } catch (error) {
                console.error('Error sending viewed request:', error);
            }
        };

        if (id) {
            sendViewedRequest();
        }
    }, [id]);

    if(error) {
        return <NotFoundError/>
    }

    if (!article) {
        return <Loader/>;
    }

    return (
        <>
            <section className="articleDetails-container">
                <h1 className="articleDetails-title">{article.title}</h1>
                <Timer creationDate={article.creationDate}/>
                <div className="articleDetails-summary">
                    {article.summary.map((line, index) => (
                        <div className="articleDetails-pointLine" key={index}>
                            <div className="articleDetails-pointLine-dot"></div>
                            <p className="articleDetails-pointLine-text">{line}</p>
                        </div>
                    ))}
                </div>

                {article.youtubeUrl && article.youtubeUrl.length > 0 && (
                    <div className="articleDetails-youtube">
                        <YoutubeVideo url={article.youtubeUrl}/>
                    </div>
                )}

                {article.twitterId && (
                    <div className="articleDetails-twitter">
                        <Tweet tweetId={article.twitterId} options={{ theme: theme }} />
                    </div>
                )}

                {article.stockSymbol && article.stockSymbol.length > 0 && (
                    <div className="articleDetails-tradingview">
                        <LineChartWrapper publicationStockSymbol={article.stockSymbol}/>
                    </div>
                )}

                <div className="articleDetails-numberOfSources">
                    <p className="articleDetails-numberOfSources-text">Znaleźliśmy <span
                        className="articleDetails-numberOfSources-span">{article.sourceInfo.numberOfSource}</span> {getArticleText(article.sourceInfo.numberOfSource)}
                        <br/> na ten temat:</p>
                </div>


                <div className="articleDetails-grid">
                    <div className="articleDetails-grid-mainSource">
                        {article.sourceInfo.sources[0] && (() => {
                            const formattedSourceName = article.sourceInfo.sources[0].sourceName
                                .toLowerCase()
                                .replace(/ /g, '');
                            const sourceImage = `https://storage.googleapis.com/image.verita.news/sources/${formattedSourceName}.png`;
                            return (
                                <a href={article.sourceInfo.sources[0].redirectLink} target="_blank"
                                   rel="noopener noreferrer">
                                    <div className="articleDetails-imageWrapper">
                                        <img className="articleDetails-grid-mainSource-image"
                                             src={article.sourceInfo.sources[0].sourceImageUrl}
                                             alt={article.sourceInfo.sources[0].sourceName}/>
                                    </div>
                                    <div className="articleDetails-grid-mainSource-companyWrapper">
                                        <img className="companyLogo" src={sourceImage} alt={`${sourceImage} logo`}
                                             onError={(e) => {
                                                 e.target.onerror = null;
                                                 e.target.src = "https://storage.googleapis.com/image.verita.news/sources/notFound.png";
                                             }}/>
                                        <p>{article.sourceInfo.sources[0].sourceName}</p>
                                    </div>
                                    <h4>{article.sourceInfo.sources[0].title}</h4>
                                </a>
                            );
                        })()}
                    </div>
                    <div className="articleDetails-grid-otherSources">
                        {article.sourceInfo.sources.slice(1, 4).map((source) => {
                            const formattedSourceName = source.sourceName
                                .toLowerCase()
                                .replace(/ /g, '');
                            const sourceImage = `https://storage.googleapis.com/image.verita.news/sources/${formattedSourceName}.png`;

                            return (
                                <div className="articleDetails-grid-sources-element" key={source.sourceId}>
                                    <a href={source.redirectLink} target="_blank" rel="noopener noreferrer">
                                        <div className="articleDetails-imageWrapper">
                                            <img className="articleDetails-grid-sources-element-imgOnlyMobile" src={source.sourceImageUrl} alt={source.sourceName} onError={(e ) => {
                                                e.target.onerror = null;
                                                e.target.src = placeHolderImage
                                            }}/>
                                        </div>
                                        <div className="articleDetails-grid-sources-element-companyWrapper">
                                            <img className="companyLogo" src={sourceImage} alt={`${source.sourceName} logo`}
                                                 onError={(e) => {
                                                     e.target.onerror = null;
                                                     e.target.src = "https://storage.googleapis.com/image.verita.news/sources/notFound.png";
                                                 }}/>
                                            <p className="articleDetails-grid-sources-element__sourceName">{source.sourceName}</p>
                                        </div>
                                    <h4 className="articleDetails-grid-sources-element__title">{source.title}</h4>
                                    </a>
                                </div>
                            );
                        })}
                        {isMobile && (
                            <div className="articleContent-grid-sources-element-showAllBig" key="show all" onClick={handleViewAllSources}>
                                <div className="articleContent-sources-element-banner-wrapper">
                                    <div className="articleContent-sources-element-banner">
                                        <p>Zobacz wszystkie materiały</p>
                                        <img src={arrowIcon} alt="arrow icon"/>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="articleDetails-grid-otherSources-showAll" onClick={handleViewAllSources}>
                            <div className="articleDetails-grid-otherSources-showAll-textAndIcon">
                                <img src={theme === 'light' ? newsPaperIcon : newsPaperIconDark} alt="newsPaperIcon"/>
                                <p>Zobacz wszystkie materiały</p>
                            </div>
                            <img className="arrowIconAllSources" src={arrowIcon} alt="arrow icon suggesting that user can click it"/>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default ArticleDetails;
